import './entry.d/frontend';

/* webpackChunkName: [bootstrap-4] */
import('@w3media/framework-styles/assets/bootstrap-4.js');
/* webpackChunkName: [owl-carousel] */
import('@w3media/framework-styles/assets/owl-carousel-2.js');
/* webpackChunkName: [fancybox-3] */
import( '@w3media/framework-styles/assets/fancybox-3.js');

import './_themes/default/theme.scss';
import '../vendor/w3media/framework/assets/js/V2/page-scripts';
import './js/frontend.js';
