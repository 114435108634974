(function ($) {
  $(window).load(function(){

    $('.owl-carousel:not(.slider):not(.js-parc-blocks-slider)').owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: false,
      loop: true,
      items: 1,
      lazyLoad: true,
      lazyLoadEager: 1,

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,

      // animation
      animateOut: 'fadeOut',
      animateIn: 'fadeIn',

      // navigation
      false: true,
      navText: ['<i class="fas fa-arrow-circle-left"></i>', '<i class="fas fa-arrow-circle-right"></i>'],

      dots: false,

      // drag options
      mouseDrag: true
    })

    $('.slider:not(.js-park-blocks-slider)').owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      items: 1,
      margin: 15,
      lazyLoad: true,

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,

      // navigation
      nav: false,
      navContainer: false,
      navText: ['<i class="fas fa-arrow-circle-left"></i>', '<i class="fas fa-arrow-circle-right"></i>'],
      dots: true,

      // drag options
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1
        },
        576: {
          items: 2
        },
        992: {
          items: 3
        },
      }
    });

    $('.js-park-blocks-slider').owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      items: 1,
      margin: 0,
      autoHeight: true,
      lazyLoad: true,
      lazyLoadEager: 1,

      // animation
      animateIn: 'fadeIn',
      animateOut: 'fadeOut',

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,

      // navigation
      nav: true,
      navText: ['<img src="images/arrow-left.svg">','<img src="images/arrow-right.svg">'],
      dots: true,

      // drag options
      mouseDrag: true
    });

    // form-sent
    setTimeout(() => {

      var locationSearch = '' + document.location.search;
      if (
        (-1 === locationSearch.indexOf('form[sent]') && -1 === decodeURIComponent(locationSearch).indexOf('form[sent]'))
        || -1 === document.location.hash.indexOf('#form')
      ) {
        return;
      }

      var $headerHeight = $('.header');
      var $message = $('.form_success_message');

      if (!$headerHeight.length || !$message.length) {
        return;
      }

      var currentScrollTop = $('html').scrollTop();
      var newScrollTop = $message.offset().top - $headerHeight.outerHeight() - 0;
      if (newScrollTop >= currentScrollTop) {
        return;
      }

      $('html, body').animate({ scrollTop: $message.offset().top - $headerHeight.outerHeight() - 0 });
    }, 500);

  });

  $(window).load(function () {

    var windowWidth = $(window).width();
    if (windowWidth > 1200) {
      $(window).scroll(function(){
        if ($(window).scrollTop() > 0) {
          $('body').addClass('sticky');
          $('.header').addClass('sticky');
        }
        else {
          $('body').removeClass('sticky');
          $('.header').removeClass('sticky');
        }
      });
    }

    // navbar-toggler
    $(document).on('click', '.navbar-toggler', function() {
      $('body').toggleClass('navbar-collapse-active');
    });

    // fancybox
    $('a[data-fancybox]').fancybox({
      buttons: [
          //"zoom",
          //"share",
          //"slideShow",
          //"fullScreen",
          //"download",
          "thumbs",
          "close"
      ]
    });

    // viewport-animation
    /*function isScrolledIntoView(elem) {
      var docViewTop = $(window).scrollTop();
      var docViewBottom = docViewTop + $(window).height();

      var elemTop = $(elem).offset().top;
      var elemBottom = elemTop + $(elem).height();

      return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    }
    $(window).scroll(function () {
        $('main > section').each(function () {
            if (isScrolledIntoView(this) === true) {
                $(this).addClass('viewport-animation');
            }
        });
    });*/

  });
})(jQuery);
